<template>
  <div
    class="reader-container"
    :style="{ background: defaultTheme.style.body.background }"
  >
    <reader-top-bar
      v-show="!showHtmlStr"
      :showBar="showTopBar"
      :barBackground="defaultTheme.barBackground"
      @back="back"
      :isPC="true"
    ></reader-top-bar>
    <reader-teacher-say
      :isTeacherSay.sync="isTeacherSay"
      :bookId="bookId"
      @back="hideTeacherSay"
      @goToMark="goToMark"
    ></reader-teacher-say>

    <div v-show="!showHtmlStr" :style="{ marginTop: statusBarHeight + 'px' }">
      <div id="read">
        <v-menu
          offset-y
          v-model="textMenu.showTextMenu"
          :position-x="textMenu.x"
          :position-y="textMenu.y"
        >
          <v-list class="text-menu">
            <v-list-item class="pa-0 ma-0" v-if="textMenu.isUnderLined">
              <v-row class="pa-0 ma-0 mx-1">
                <v-btn
                  elevation="1"
                  class="white--text transparent f16 text-menu-btn"
                  @click="handleAnnotion('delete')"
                  >删除</v-btn
                >
              </v-row>
            </v-list-item>
            <v-list-item class="pa-0 ma-0" v-if="!textMenu.isUnderLined">
              <v-row class="pa-0 mx-1">
                <v-btn
                  elevation="1"
                  class="white--text transparent f16 text-menu-btn"
                  @click="handleAnnotion('underline')"
                  >划线</v-btn
                >
              </v-row>
            </v-list-item>
            <v-list-item class="pa-0 ma-0">
              <v-row class="pa-0 mx-1">
                <v-btn
                  elevation="1"
                  class="white--text transparent f16 text-menu-btn"
                  @click="handleAnnotion('note')"
                  >笔记</v-btn
                >
              </v-row>
            </v-list-item>
            <v-list-item class="pa-0 ma-0">
              <v-row class="pa-0 mx-1">
                <v-btn
                  elevation="1"
                  class="white--text transparent f16 text-menu-btn"
                  @click="handleAnnotion('pinyin')"
                  >拼音</v-btn
                >
              </v-row>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-row
        v-if="!isFirstLoad"
        class="reader-bottom-info transparent pa-0 ma-0 px-6"
        justify="space-between"
        :style="{ color: defaultTheme.style.body.color }"
      >
        <p
          class="my-auto transparent f12"
          :style="{ color: defaultTheme.style.body.color }"
        >
          {{ progress }}%
        </p>
        <p
          class="my-auto transparent f12"
          :style="{ color: defaultTheme.style.body.color }"
        >
          已阅读 {{ hasReadMin }} 分钟
        </p>
        <p
          class="my-auto transparent f12"
          :style="{ color: defaultTheme.style.body.color }"
        >
          {{ page }}页
        </p>
      </v-row>
    </div>

    <v-img
      v-if="teacherTag && noteMode == 0"
      elevation="0"
      class="tec-tag white--text f12"
      :style="'margin-top:' + statusBarHeight + 'px'"
      @click.native="noteModeTagClick"
      src="@/assets/tec-mark-tag.png"
      width="45"
      aspect-ratio="0.72"
    >
    </v-img>

    <v-img
      v-if="teacherTag && noteMode == 1"
      elevation="0"
      class="tec-tag white--text f12"
      :style="'margin-top:' + statusBarHeight + 'px'"
      @click.native="noteModeTagClick"
      src="@/assets/my-mark-tag.png"
      width="45"
      aspect-ratio="0.72"
    >
    </v-img>

    <reader-bottom-bar
      ref="bottomBar"
      :bookInfo="bookInfo"
      :bookId="bookId"
      :showBar="showBottomBar"
      @getTheme="getTheme"
      @getFontSize="getFontSize"
      :progress="progress"
      @showTeacherSay="showTeacherSay"
      @jumpTo="jumpTo"
      @writeNote="noteBtnClickHandler"
      @hideBar="hideBar"
      v-show="!showHtmlStr"
    ></reader-bottom-bar>

    <p v-html="htmlStr" v-show="showHtmlStr"></p>

    <reader-note-comments
      :dialog.sync="isShowNoteComments"
      :annotation="selectedTeacherAnnotation"
    ></reader-note-comments>
    <!-- 笔记输入框 -->

    <reader-note-text-field
      :dialog="showNoteInput"
      :note="noteText"
      :selectedText="selectedText"
      :noteInputFrom="noteInputFrom"
      @saveNote="handleSaveNote"
      @hideInput="handleHideTextField"
    ></reader-note-text-field>

    <reader-note-comment-text-field
      :dialog.sync="isShowCommentTextField"
      :selectedText="selectedText"
      @save="saveCommentNote"
    ></reader-note-comment-text-field>

    <v-btn
      class="last-section pa-0 ma-0"
      icon
      rounded
      elevation="1"
      v-show="showPrev"
      @click="rendition.prev()"
    >
      <v-icon color="primary">icon-uniE939</v-icon>
    </v-btn>

    <v-btn
      class="next-section pa-0 ma-0"
      icon
      rounded
      elevation="1"
      v-show="showNext"
      @click="rendition.next()"
    >
      <v-icon color="primary">icon-right-arrow</v-icon>
    </v-btn>

    <v-scale-transition v-if="audioData.length">
      <v-btn
        class="note-btn pa-0 ma-0"
        icon
        rounded
        v-show="isShowNoteBtn && noteMode == 0"
        @click="playSound"
      >
        <v-icon color="white">icon-hear</v-icon>
      </v-btn>
    </v-scale-transition>

    <reader-note-tip
      :dialog="noteTipVisible"
      :note="selectedAnnotationNote"
      @hideTip="hideNoteTip"
      @tipClick="tipClickHandler"
    ></reader-note-tip>

    <reader-bottom-note
      :dialog.sync="showTeacherNote"
      :annotation="selectedTeacherAnnotation"
    >
    </reader-bottom-note>

    <reader-pinyin
      :dialog.sync="showPinyin"
      :pinyinText="pinyinText"
    ></reader-pinyin>

    <reader-audio-player
      :dialog.sync="showAudioPlayer"
      :cover="bookInfo.cover"
      :audioData="audioData"
    ></reader-audio-player>
    <audio id="audio"></audio>

    <c-loading v-show="showCLoading" background="transparent"></c-loading>
  </div>
</template>

<script>
import CLoading from "@/components/c-loading/c-loading";
import ReaderTeacherSay from "@/views/common/web/reader/components/reader-teacher-say/reader-teacher-say";
import ReaderTopBar from "@/views/common/web/reader/components/reader-top/reader-top-bar";
import ReaderBottomBar from "@/views/common/web/reader/components/reader-bottom/reader-bottom-bar";
import ReaderNoteComments from "@/views/common/web/reader/reader-note/reader-note-comments";
import ReaderNoteTextField from "@/views/common/web/reader/reader-note/reader-note-text-field";
import ReaderNoteCommentTextField from "@/views/common/web/reader/reader-note/reader-note-comment-text-field";
import ReaderNoteTip from "@/views/common/web/reader/reader-note/reader-note-tip";
import ReaderBottomNote from "@/views/common/web/reader/reader-note/reader-bottom-note";
import VClamp from "vue-clamp";

import ReaderUtil from "@/views/common/web/reader/reader-utils/ReaderUtil.js";
import PcMixins from "@/views/pc/_mixins/pc-mixins.js";
import ReaderPinyin from "@/views/common/web/reader/components/reader-pinyin";
import ReaderAudioPlayer from "@/views/common/web/reader/components/reader-audio-player";

export default {
  mixins: [PcMixins],
  name: "pc-reader",
  provide: ReaderUtil.provide,
  data: ReaderUtil.data,
  components: {
    CLoading,
    ReaderTeacherSay,
    ReaderTopBar,
    ReaderBottomBar,
    ReaderNoteComments,
    VClamp,
    ReaderNoteTextField,
    ReaderBottomNote,
    ReaderNoteTip,
    ReaderNoteCommentTextField,
    ReaderPinyin,
    ReaderAudioPlayer,
  },
  computed: ReaderUtil.computed,
  methods: ReaderUtil.pc_methods(),
  watch: ReaderUtil.watch,
  created: ReaderUtil.created,
  mounted: ReaderUtil.mounted,
  beforeRouteLeave(to, from, next) {
    if (this.bookAvailable) {
      this.readEndTime = this.$moment().format("YYYY-MM-DD HH:mm:ss");
      this.readLastCfi = this.rendition.location.start.cfi;
      this.postLastBookInfo();
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/anim";
.reader-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.tec-tag {
  position: absolute;
  top: 0px;
  background: transparent;
  right: 30px;
}

.triangle-up {
  background: transparent;
  width: 0;
  height: 0;
  margin-left: 4px; //calc(50% - 4px) !important;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 16px solid #403e3d;
}

.text-menu {
  // width: 80px;
  background: #403e3d;
  border-radius: 3px;
}

.text-menu-btn {
  :hover {
    color: var(--v-primary-base);
  }
}

.v-menu__content {
  box-shadow: none !important;
}

.reader-bottom-info {
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 100%;
}

.last-section {
  position: absolute;
  right: 40px;
  bottom: 270px;
  width: 40px;
  height: 40px;
  background: white;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}

.next-section {
  position: absolute;
  right: 40px;
  bottom: 210px;
  width: 40px;
  height: 40px;
  background: white;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}

.note-btn {
  position: absolute;
  right: 40px;
  bottom: 120px;
  width: 40px;
  height: 40px;
  background: #4d9f7c;
  box-shadow: 0px 2px 4px 0px #4d9f7c;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}

@supports (bottom: constant(safe-area-inset-bottom)) or
  (bottom: env(safe-area-inset-bottom)) {
  .bottom-div {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
  }
}
</style>
