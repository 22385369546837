<template>
  <div>
    <div class="white" :style="{ height: statusBarHeight }"></div>
    <v-row
      class="reader-teacher-say-top-bar-container pa-0 mx-0"
      align="center"
      justify="center"
    >
      <v-col
        class="reader-teacher-say-top-bar-left pa-0 ma-0"
        cols="auto"
        @click="clickBack"
      >
        <v-row
          class="reader-teacher-say-top-bar-icon pa-0 ma-0"
          align="center"
          justify="center"
        >
          <v-icon color="#4CA07C" size="20">icon-return</v-icon>
        </v-row>
      </v-col>

      <div class="reader-teacher-say-top-bar-title">{{ title }}</div>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "reader-teacher-say-top-bar",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      statusBarHeight: "0px",
    };
  },
  components: {},
  computed: {},
  methods: {
    clickBack() {
      this.$emit("back");
    },
  },
  watch: {},
  created() {
    this.$native.getStatusBarHeight().then((height) => {
      this.statusBarHeight = height + "px";
    });
  },
};
</script>

<style lang="scss" scoped>
.reader-teacher-say-top-bar-container {
  position: relative;
  height: 48px;
  background: white;
  box-shadow: 0px 2px 7px 0px rgba(232, 232, 232, 0.7);
  z-index: 1001;
  .reader-teacher-say-top-bar-left {
    position: absolute;
    left: 0;
    .reader-teacher-say-top-bar-icon {
      width: 44px;
      height: 48px;
    }
  }
  .reader-teacher-say-top-bar-title {
    line-height: 24px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
  }
}
</style>
