<template>
  <v-col class="pa-0 mx-0 white" :style="{ marginTop: marginTop }">
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        <v-row class="reader-teacher-say-item-user pa-0" align="center">
          <v-img
            class="reader-teacher-say-item-avatar"
            :src="item.user.headImg"
            width="30"
            height="30"
            max-width="30"
            max-height="30"
            aspect-ratio="1"
          ></v-img>
          <div class="reader-teacher-say-item-name">{{ item.user.name }}</div>
        </v-row>
        <v-row class="reader-teacher-say-item-note pa-0">{{ item.note }}</v-row>
        <v-row class="reader-teacher-say-item-date pa-0">{{ item.date }}</v-row>
        <div class="reader-teacher-say-item-line-1"></div>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0 mx-2" @click="clickMark(item)">
      <v-col class="pa-0 ma-0">
        <v-clamp
          class="reader-teacher-say-item-mark pa-0"
          autoresize
          :max-lines="2"
        >
          {{ item.markedText }}
        </v-clamp>
        <div class="reader-teacher-say-item-line-2"></div>
      </v-col>
    </v-row>
    <reader-teacher-say-student-note
      v-if="item.epubcfi"
      :note="item"
    ></reader-teacher-say-student-note>
  </v-col>
</template>

<script>
import { getEPubTeacherSayStudentNotes } from "@/api/library";
import { isEmpty } from "@/views/common/web/isEmpty";
import ReaderTeacherSayStudentNote from "@/views/common/web/reader/components/reader-teacher-say/reader-teacher-say-student-note";
import VClamp from "vue-clamp";
import PlaceholderImg from "@/assets/img_placeholder_avatar_s.png";
export default {
  name: "reader-teacher-say-item",
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    marginTop: {
      type: String,
      default: "7px",
    },
  },
  data() {
    return {
      notes: [],
    };
  },
  components: {
    ReaderTeacherSayStudentNote,
    VClamp,
  },
  computed: {},
  methods: {
    // getStudentNotes() {
    //   getEPubTeacherSayStudentNotes(this.item.id)
    //     .then((res) => {
    //       if (!res) {
    //         console.log(">>>>>getEPubTeacherSayStudentNotes错误");
    //       } else {
    //         console.log(">>>>>getEPubTeacherSayStudentNotes成功");
    //         this.notes = res.studentNotes;
    //         if (this.notes) {
    //           this.notes.map((note) => {
    //             if (note && isEmpty(note.headImg)) {
    //               note.headImg = PlaceholderImg;
    //             }
    //           });
    //         }
    //       }
    //     })
    //     .catch(({ res, statusCode }) => {
    //       console.log(">>>>>getEPubTeacherSayStudentNotes失败");
    //       if (!res || !res.message) {
    //         return;
    //       }

    //       this.showSnackbar({ msg: res.message });
    //     });
    // },
    clickMark(item) {
      this.$emit("goToMark", item.epubcfi);
      if (item.id) {
        //埋点click_note
        this.$track.event({
          category: "reader",
          name: "click_note",
          value: item.id,
          params: {
            source_page: "student_note_page",
          },
        });
      }
    },
    clickMore() {
      console.log(">>>clickMore");
    },
  },
  watch: {},
  created() {
    // this.getStudentNotes();
  },
};
</script>

<style lang="scss" scoped>
.reader-teacher-say-item-user {
  margin: 20px 24px 0px;
  .reader-teacher-say-item-avatar {
    border-radius: 50%;
  }
  .reader-teacher-say-item-name {
    height: 22px;
    line-height: 22px;
    margin-left: 13px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #444444;
    letter-spacing: 1px;
  }
}
.reader-teacher-say-item-note {
  line-height: 24px;
  margin: 16px 20px 0px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
}
.reader-teacher-say-item-date {
  height: 20px;
  line-height: 20px;
  margin: 10px 20px 0px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  letter-spacing: 1px;
}
.reader-teacher-say-item-line-1 {
  height: 2px;
  margin: 10px 20px 0px;
  background: #f8f8f8;
}
.reader-teacher-say-item-mark {
  line-height: 24px;
  margin: 20px 16px 0px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #707f8d;
}
.reader-teacher-say-item-line-2 {
  height: 2px;
  margin: 16px 20px 0px;
  background: #f8f8f8;
}
.reader-teacher-say-item-student-note {
  height: 22px;
  line-height: 22px;
  margin: 40px 20px 0px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #444444;
  letter-spacing: 1px;
}
.reader-teacher-say-item-line-3 {
  height: 2px;
  margin: 6px 20px 0px;
  background: #f8f8f8;
}
.reader-teacher-say-more {
  margin: 16px 16px 30px;
  .reader-teacher-say-more-text {
    line-height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #707f8d;
  }
}
</style>
