<template>
  <v-lazy v-model="isShow">
    <div>
      <p class="mx-6 common--text font-weight-bold f16">同学笔记</p>
      <v-divider class="mx-6 backF8"></v-divider>
      <v-list class="pa-0 ma-0">
        <v-list-item
          v-for="({ user, praised, praisedAmt, note, id }, i) in commentsTwo"
          :key="id"
          class="my-2"
        >
          <v-list-item-avatar class="mt-0 pt-0 head">
            <v-img
              :src="user.headImg"
              max-width="30px"
              max-height="30px"
              width="30px"
              height="30px"
              aspect-ratio="1"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="content pa-0">
            <v-row justify="space-between" class="pa-0 ma-0">
              <v-clamp
                autoresize
                :max-lines="1"
                class="f14 blueGray--text mx-4 my-auto"
                >{{ user.name }}</v-clamp
              >
              <v-btn
                @click.stop="praiseClickHandler(comments[i])"
                text
                class="blueGray--text pa-0"
              >
                <v-icon size="16" :color="praised ? 'primary' : 'blueGray'"
                  >icon-Fabulous</v-icon
                >
                <p
                  class="my-auto ml-1"
                  :class="praised ? 'primary--text' : 'blueGray--text'"
                >
                  {{ praisedAmt }}
                </p>
              </v-btn>
            </v-row>
            <v-clamp
              autoresize
              :max-lines="2"
              class="f14 common--text ml-4 pr-6 my-2"
              >{{ note }}</v-clamp
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-btn text @click="nextClickHandler" class="f14 blueGray--text"
        >{{
          comments.length > 0
            ? "全部" + comments.length + "条笔记"
            : "做第一个写笔记的人吧"
        }}
        <v-icon class="ml-2" size="12">icon-return mdi-rotate-180</v-icon>
      </v-btn>
    </div>
  </v-lazy>
</template>
<script>
import {
  praiseStudentComment,
  getStudentReaderComments,
  getTeacherReaderComments,
  teacherPraiseStudentComment,
} from "@/api/library";
import VClamp from "vue-clamp";
export default {
  name: "reader-teacher-say-student-note",
  props: {
    note: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      comments: [],
      isShow: false,
    };
  },
  inject: [
    "setComments",
    "showNoteComments",
    "hideTeacherSay",
    "setSelectedTeacherAnnotationData",
    "showCommentTextField",
    "isTeacher",
  ],
  methods: {
    nextClickHandler() {
      this.setComments(this.comments);
      this.$nextTick(() => {
        this.setSelectedTeacherAnnotationData(this.note);
        if (this.comments.length > 0) {
          this.showNoteComments();
        } else {
          this.showCommentTextField();
        }
      });
    },
    praiseClickHandler(comment) {
      this.isShow = false;
      this.isShow = true;
      if (this.isTeacher) {
        teacherPraiseStudentComment(comment.id)
          .then(() => {
            comment.praised = 1;
            comment.praisedAmt++;
          })
          .catch(({ res, statusCode }) => {
            if (!res || !res.message) {
              return;
            }

            this.showSnackbar({ msg: res.message });
          });
      } else {
        praiseStudentComment(comment.id)
          .then(() => {
            comment.praised = 1;
            comment.praisedAmt++;
          })
          .catch(({ res, statusCode }) => {
            if (!res || !res.message) {
              return;
            }

            this.showSnackbar({ msg: res.message });
          });
      }
    },
    getCommentList() {
      if (this.isTeacher) {
        getTeacherReaderComments(this.note.id).then((res) => {
          this.comments = res.comments;
        });
      } else {
        getStudentReaderComments(this.note.id).then((res) => {
          this.comments = res.comments;
        });
      }
    },
  },
  created() {
    this.$root.$on("commentNoteSaved", (noteId, note) => {
      if (noteId == this.note.id) {
        this.getCommentList();
      }
    });
  },
  watch: {
    isShow(val) {
      if (val) {
        this.getCommentList();
      }
    },
  },
  computed: {
    commentsTwo() {
      return _.dropRight(this.comments, this.comments.length - 2);
    },
  },
  components: {
    VClamp,
  },
};
</script>

<style lang="scss" scoped>
.head {
  position: absolute;
  top: 0;
}

.content {
  margin-left: 38px;
}
</style>
