<template>
  <v-bottom-sheet
    overlay-opacity="0"
    class="pa-0 ma-0"
    scrollable
    max-width="900px"
    v-model="visible"
  >
    <v-card class="pa-0  pb-6 card">
      <v-card-title class="pa-0 ma-4 mt-3">
        <v-avatar class="ml-n2 pa-0">
          <v-img
            :src="user.headImg"
            min-width="30px"
            min-height="30px"
            max-height="30px"
            max-width="30px"
            aspect-ratio="1"
          ></v-img>
        </v-avatar>
        <p class="f16 common--text font-weight-bold  my-auto mx-2">
          {{ user.name }}
        </p>
      </v-card-title>
      <p class="f16 common--text mx-4">{{ note.note }}</p>
      <p class="f14 minor--text mx-4">{{ createAtStr }}</p>
      <reader-bottom-note-comments
        :comments="comments"
      ></reader-bottom-note-comments>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import {
  getStudentReaderComments,
  getTeacherReaderComments,
} from "@/api/library";
import ReaderBottomNoteComments from "@/views/common/web/reader/reader-note/reader-bottom-note-comments";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    annotation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      comments: [],
    };
  },
  inject: ["setComments", "isTeacher"],
  methods: {
    getCommentList() {
      const { id } = this.annotation.data;
      if (this.isTeacher) {
        getTeacherReaderComments(id).then((res) => {
          this.comments = res.comments;
          this.setComments(this.comments);
        });
      } else {
        getStudentReaderComments(id).then((res) => {
          this.comments = res.comments;
          this.setComments(this.comments);
        });
      }
    },
  },
  computed: {
    visible: {
      get() {
        return this.dialog;
      },
      set(v) {
        this.$emit("update:dialog", v);
      },
    },
    note() {
      return this.annotation.data;
    },
    user() {
      const { user } = this.annotation.data;
      if (user) {
        return user;
      } else {
        return { headImg: "" };
      }
    },
    createAtStr() {
      const { createdAt, updatedAt } = this.note;
      var timeInt = createdAt;
      var headStr = "发布";
      if (updatedAt != createdAt) {
        timeInt = updatedAt;
        headStr = "修改";
      }

      var timestamp = new Date(parseInt(timeInt) * 1000);
      let timeStr = this.$moment(timestamp).format("YYYY-MM-DD");
      return headStr + timeStr;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getCommentList();
      }
    },
  },
  components: {
    ReaderBottomNoteComments,
  },
};
</script>

<style>
.card {
  border-radius: 16px 16px 0px 0px !important;
}
</style>
