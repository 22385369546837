<template>
  <v-dialog v-model="visible" transition="slide-x-transition">
    <reader-teacher-say-top-bar
      title="老师说"
      @back="back"
    ></reader-teacher-say-top-bar>
    <div class="pa-0 ma-0 backF8 list" :style="{ height: listHeight }">
      <reader-teacher-say-item
        v-for="(item, index) in items"
        :key="item.id"
        :item="item"
        :index="index"
        :marginTop="index === 0 ? '0px' : '7px'"
        @goToMark="goToMark"
      ></reader-teacher-say-item>
      <c-loading v-show="showCLoading"></c-loading>
      <c-empty :isShow="showEmpty"></c-empty>
    </div>
  </v-dialog>
</template>

<script>
import { getEPubTeacherSay } from "@/api/library";
import ReaderTeacherSayTopBar from "@/views/common/web/reader/components/reader-teacher-say/reader-teacher-say-top-bar";
import ReaderTeacherSayItem from "@/views/common/web/reader/components/reader-teacher-say/reader-teacher-say-item";
import CLoading from "@/components/c-loading/c-loading";
import CEmpty from "@/components/c-empty";
export default {
  name: "reader-teacher-say",
  props: {
    isTeacherSay: {
      type: Boolean,
      default: false,
    },
    bookId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      listHeight: "calc(100% - 48px)",
      items: [],
      showCLoading: true,
      showEmpty: false,
    };
  },
  components: {
    ReaderTeacherSayTopBar,
    ReaderTeacherSayItem,
    CLoading,
    CEmpty,
  },
  computed: {
    visible: {
      get() {
        return this.isTeacherSay;
      },
      set(v) {
        this.$emit("update:isTeacherSay", v);
      },
    },
  },
  methods: {
    back() {
      this.$emit("back");
    },
    getTeacherSay() {
      getEPubTeacherSay(this.bookId)
        .then((res) => {
          this.showCLoading = false;
          if (!res || res.notes.length === 0) {
            console.log(">>>>>getEPubTeacherSay错误");
            this.showEmpty = true;
          } else {
            this.showEmpty = false;
            console.log(">>>>>getEPubTeacherSay成功", res);
            this.items = res.notes;
            if (this.items) {
              this.items.map((item) => {
                item.date =
                  item.createdAt >= item.updatedAt
                    ? "发布" +
                      this.$moment(item.createdAt * 1000).format("YYYY-MM-DD")
                    : "修改" +
                      this.$moment(item.updatedAt * 1000).format("YYYY-MM-DD");
              });
            }
          }
        })
        .catch(({ res, statusCode }) => {
          this.showCLoading = false;
          this.showEmpty = true;
          console.log(">>>>>getEPubTeacherSay失败");
          if (!res || !res.message) {
            return;
          }

          this.showSnackbar({ msg: res.message });
        });
    },
    goToMark(cfi) {
      if (cfi) {
        this.$emit("goToMark", cfi);
      }
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.getTeacherSay();
      } else {
        this.items = [];
      }
    },
  },
  created() {
    this.$native.getStatusBarHeight().then((height) => {
      var topHeight = parseInt(height) + 48;
      this.listHeight = "calc(100% - " + topHeight + "px";
    });
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  position: relative;
  top: 0;
}

.list {
  overflow: auto;
}

::v-deep .v-dialog {
  max-height: 100%;
  margin: 0;
  background: rgba(64, 62, 61, 0.9);
  height: 100%;
}

.v-dialog__content {
  width: 85%;
  max-width: 400px;
  height: 100%;
  justify-content: left;
}
</style>
