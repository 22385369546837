<template>
  <v-dialog v-model="visible" overlay-opacity="0" max-width="400">
    <v-card
      elevation="0"
      class="content ma-0 pa-0"
      :ripple="false"
      @click="handleTipClickAction"
    >
      <p class="ma-4 transparent common--text f16">{{ note }}</p>
    </v-card>
  </v-dialog>
</template>

<script>
import VClamp from "vue-clamp";
export default {
  name: "reader-note-tip",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    note: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      noteText: "",
      visible: false,
    };
  },
  methods: {
    handleTipClickAction() {
      this.$emit("tipClick", this.noteText);
    },
  },
  watch: {
    dialog(val) {
      this.visible = this.dialog;
    },
    visible(val) {
      if (val == false) {
        this.$emit("hideTip", "");
      }
    },
  },
  components: {
    VClamp,
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: 200px;
  background: #fff9cf !important;
}

::v-deep .v-dialog {
  background: #fff9cf !important;
  box-shadow: 0px 0px 6px 0px #999999 !important;
}
</style>
