import { render, staticRenderFns } from "./reader-teacher-say-student-note.vue?vue&type=template&id=77f8811e&scoped=true&"
import script from "./reader-teacher-say-student-note.vue?vue&type=script&lang=js&"
export * from "./reader-teacher-say-student-note.vue?vue&type=script&lang=js&"
import style0 from "./reader-teacher-say-student-note.vue?vue&type=style&index=0&id=77f8811e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77f8811e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VDivider,VIcon,VImg,VLazy,VList,VListItem,VListItemAvatar,VListItemContent,VRow})
