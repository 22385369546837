<template>
  <v-dialog v-model="visible" max-width="400">
    <v-card>
      <v-textarea
        placeholder="记录你此刻的感想"
        class="ma-0 transparent"
        color="common"
        v-model="noteText"
        height="160px"
        rounded
        autofocus
      ></v-textarea>
      <v-clamp class="f14 minor--text mt-n4 mx-4" autoresize :max-lines="1">{{
        selectedText
      }}</v-clamp>
      <v-row class="mx-4 py-4" justify="space-between">
        <p class="my-auto f14" style="color:#c4c4c4">
          {{ noteText.length }}/200
        </p>
        <p v-if="errorMsg" class="red--text f14 my-auto">
          {{ errorMsg }}
        </p>
        <v-btn
          elevation="1"
          class="primary my-auto"
          :class="noteText.length == 0 ? 'minor' : 'primary'"
          @click="handleSaveAction"
          >保存</v-btn
        >
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import VClamp from "vue-clamp";
export default {
  name: "reader-note-comment-text-field",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    selectedText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      noteText: "",
      errorMsg: "",
    };
  },
  methods: {
    handleSaveAction() {
      if (this.noteText == "") {
        this.errorMsg = "请输入笔记内容";
        return;
      } else {
        this.$emit("save", this.noteText);
        this.noteText = "";
      }
    },
  },
  watch: {
    noteText(val) {
      if (val.length > 200) {
        this.$nextTick(() => {
          this.noteText = val.substring(0, 200);
          this.errorMsg = "字数上限200";
        });
      }
    },
    errorMsg(val) {
      if (val) {
        setTimeout(() => {
          this.errorMsg = "";
        }, 2000);
      }
    },
  },
  computed: {
    visible: {
      get() {
        return this.dialog;
      },
      set(v) {
        this.$emit("update:dialog", v);
      },
    },
  },
  components: {
    VClamp,
  },
};
</script>

<style></style>
